<template>
  <div>
    <div v-for="(item, index) in dataList" :key="item.id" class="list">
      <el-row v-if="item.treeId === 1" :gutter="20">
        <el-col :span="4">
          <!-- 图片区域 -->
          <div v-if="item.imgList && item.imgList.length > 0" class="swiper-box">
            <div class="swiper-container" :class="`img-top${index}`">
              <div class="swiper-wrapper">
                <el-image v-for="(imgItem, imgIndex) in item.imgList" :key="imgIndex" :src="imgItem" class="list-left-image swiper-slide" fit="contain" lazy></el-image>
              </div>
              <div class="swiper-button-white" :class="`swiper-button-prev_${index}`"></div>
              <div class="swiper-button-white" :class="`swiper-button-next_${index}`"></div>
            </div>
            <div class="swiper-container" :class="`img-thumbs${index}`">
              <div class="swiper-wrapper">
                <el-image v-for="(imgItem, imgIndex) in item.imgList" :key="imgIndex" :src="imgItem" fit="contain" class="list-image-thumbs swiper-slide" lazy></el-image>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="not-image"></div>
            <div class="not-thumbs-box">
              <div class="not-image2"></div>
              <div class="not-image2"></div>
              <div class="not-image2"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="item-title">
            <div class="item-title-content">
              <el-image :src="shopLogo" class="shop-log"></el-image>
              <p class="list-title-content">
                <el-link :underline="false" @click="detailHandle(item)">{{
                  item.storeName
                }}</el-link>
              </p>
            </div>
          </div>
          <div class="list-rate">
            <!-- <el-rate v-model="item.storeScore" disabled :show-score="false" text-color="#ff9900"  score-template="{value}"> </el-rate> -->
          </div>
          <!-- <el-divider></el-divider> -->
          <div class="list-detail">
            <p class="detail-content">
              <span v-if="item.address">{{ $t("地址") }}：<span style="white-space: pre-wrap;">{{
                  item.address
                }}</span></span>
              <!-- <span v-else>{{ $t('地址') }}：-</span> -->
            </p>
          </div>
          <!-- <div v-if="item.equipment" class="list-service" >
            <div v-for="(serviceItem, serviceIndex) in service" :key="serviceItem.id" class="service-item">
              <el-image v-if="item.equipment && item.equipment.indexOf(serviceItem.value) !== -1" :src="serviceItem.icon" class="service-logo" lazy></el-image>
              <span v-if="item.equipment && item.equipment.indexOf(serviceItem.value) !== -1" class="service-content">{{ $t(serviceItem.label) }}</span>
              <el-divider v-if="serviceIndex < service.length - 1 && item.equipment.indexOf(serviceItem.value) !== -1" direction="vertical"></el-divider>
            </div>
          </div> -->
          <div class="list-info">
            <div v-show="item.storeTypes && item.storeTypes.length > 0 && item.treeId === 1" class="list-info-item">
              {{ $t("类型") }}：
              <span v-if="item.storeTypes && item.storeTypes.length > 0">
                <span v-for="(type, typeIndex) in item.storeTypes" :key="typeIndex">
                  <span v-show="typeIndex > 0">·</span>
                  {{ $t(shopType[type]) }}
                </span>
              </span>
              <span v-else>-</span>
            </div>
            <!-- <span><span v-if="item.startBusinessTimeWeek === 0 && item.endBusinessTimeWeek === 0">{{ $t('周一') }}~{{ $t('周日') }}</span><span v-else>{{ $t(weeks[item.startBusinessTimeWeek]) }} ~ {{ $t(weeks[item.endBusinessTimeWeek ]) }}</span></span> -->
            <div>
              {{ $t("营业时间") }}：<span><span v-if="
                    item.startBusinessTimeWeek === 0 &&
                      item.endBusinessTimeWeek === 0
                  ">{{ $t("周一") }}~{{ $t("周日") }}</span><span v-else>{{ $t(weeks[item.startBusinessTimeWeek]) }} ~
                  {{ $t(weeks[item.endBusinessTimeWeek]) }}</span></span>
              <!-- <span v-if="item.storeBusinessTimeDtoList && item.storeBusinessTimeDtoList.length > 0">
                <span v-for="(timeItem, timeIndex) in item.storeBusinessTimeDtoList" :key="timeItem.id" style="margin-right: 20px">
                  <span>
                    <span>{{ weeks[timeItem.weeks[0]] }}</span>
                    <span>~</span>
                    <span>{{ weeks[timeItem.weeks[timeItem.weeks.length - 1]] }}</span>
                  </span>
                  <span style="margin-right: 20px">
                    <span>{{ timeItem.startTime | timeFilter }}</span>
                    <span>-</span>
                    <span>{{ timeItem.endTime | timeFilter}}</span>
                  </span>
                  <el-divider v-if="timeIndex < item.storeBusinessTimeDtoList.length - 1"  direction="vertical"></el-divider>
                </span>
              </span>
              <span v-else>{{ $t('周一') }}~{{ $t('周日') }}</span> -->
            </div>
          </div>
          <div class="list-c2">
            <div>
              <div class="list-cost">
                <p v-if="item.breakfast > 0">
                  {{ $t("早餐") }}：<span class="cost-text">¥ {{ item.breakfast | moneyFilter
                    }}<span v-if="item.maxBreakfast">
                      - {{ item.maxBreakfast | moneyFilter }}</span></span>
                  <el-divider direction="vertical"></el-divider>
                </p>
                <p v-if="item.lunch > 0">
                  {{ $t("午餐") }}：<span class="cost-text">¥ {{ item.lunch | moneyFilter
                    }}<span v-if="item.maxLunch">
                      - {{ item.maxLunch | moneyFilter }}</span></span>
                  <el-divider direction="vertical"></el-divider>
                </p>
                <p v-if="item.dinner > 0">
                  {{ $t("晚餐") }}：<span class="cost-text">¥ {{ item.dinner | moneyFilter
                    }}<span v-if="item.maxDinner">
                      - {{ item.maxDinner | moneyFilter }}</span></span>
                </p>
              </div>
              <div class="list-detail">
                <p class="detail-content">
                  <span v-if="item.boxNumber" class="mr-20">{{ $t("包厢容纳人数") }}：{{ item.boxNumber }}</span>
                  <!-- <span v-else class="mr-20"></span> -->
                  <span v-if="item.seatsNumber">{{ $t("席位数") }}：{{ item.seatsNumber }}</span>
                  <!-- <span v-else></span> -->
                </p>
              </div>
            </div>
            <el-button class="cost-btn" size="medium" @click="detailHandle(item)">{{ $t("进入店铺") }}</el-button>
          </div>
          <div class="cost-discount">
            <div class="cost-title">
              Tripellet{{ $t("折扣率") }}：<span v-if="item.tripelletSaleRate" class="const-red-content">{{ item.tripelletSaleRate }}%</span><span v-else>-</span>
            </div>
            <div class="cost-title">
              {{ $t("大阪乐游劵折扣率") }}：<span v-if="item.osakaSaleRate" class="const-red-content">{{ item.osakaSaleRate }}%</span><span v-else>-</span>
            </div>
            <!-- 折扣率 -->
            <!-- <div class="cost-title">
              JR ALLPASS{{ $t("折扣率") }}：<span v-if="item.jrSaleRate" class="const-red-content">{{ item.jrSaleRate }}%</span><span v-else>-</span>
            </div>
            <div class="cost-title">
              Tokyo Joyful{{ $t("折扣率") }}：<span v-if="item.tokyoSaleRate" class="const-red-content">{{ item.tokyoSaleRate }}%</span><span v-else>-</span>
            </div> -->
          </div>
          <div class="cost-discount">
            <div v-if="item.featureProvide" class="cost-title">
              {{ $t("特典提供") }}：<span>{{ item.featureProvide }}</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="item.treeId === 2" :gutter="20">
        <el-col :span="4">
          <!-- 图片区域 -->
          <div v-if="item.imgList && item.imgList.length > 0" class="swiper-box">
            <div class="swiper-container" :class="`img-top${index}`">
              <div class="swiper-wrapper">
                <el-image v-for="(imgItem, imgIndex) in item.imgList" :key="imgIndex" :src="imgItem" fit="contain" class="list-left-image swiper-slide" lazy></el-image>
              </div>
              <div class="swiper-button-white" :class="`swiper-button-prev_${index}`"></div>
              <div class="swiper-button-white" :class="`swiper-button-next_${index}`"></div>
            </div>
            <div class="swiper-container" :class="`img-thumbs${index}`">
              <div class="swiper-wrapper">
                <el-image v-for="(imgItem, imgIndex) in item.imgList" :key="imgIndex" :src="imgItem" fit="contain" class="list-image-thumbs swiper-slide" lazy></el-image>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="not-image"></div>
            <div class="not-thumbs-box">
              <div class="not-image2"></div>
              <div class="not-image2"></div>
              <div class="not-image2"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="item-title">
            <div class="item-title-content">
              <el-image :src="shopLogo" class="shop-log"></el-image>
              <p class="list-title-content">
                <el-link :underline="false" @click="detailHandle(item)">{{
                  item.storeName
                }}</el-link>
              </p>
            </div>
          </div>
          <div class="list-rate">
            <!-- <el-rate v-model="item.storeScore" disabled :show-score="false" text-color="#ff9900"  score-template="{value}"> </el-rate> -->
          </div>
          <!-- <el-divider></el-divider> -->
          <div class="list-detail">
            <p class="detail-content">
              <span v-if="item.address">{{ $t("地址") }}：<span style="white-space: pre-wrap;">{{
                  item.address
                }}</span></span>
              <!-- <span v-else>{{ $t('地址') }}：-</span> -->
            </p>
          </div>
          <!-- <div v-if="item.equipment" class="list-service" >
            <div v-for="(serviceItem, serviceIndex) in service" :key="serviceItem.id" class="service-item">
              <el-image v-if="item.equipment && item.equipment.indexOf(serviceItem.value) !== -1" :src="serviceItem.icon" class="service-logo" lazy></el-image>
              <span v-if="item.equipment && item.equipment.indexOf(serviceItem.value) !== -1" class="service-content">{{ $t(serviceItem.label) }}</span>
              <el-divider v-if="serviceIndex < service.length - 1 && item.equipment.indexOf(serviceItem.value) !== -1" direction="vertical"></el-divider>
            </div>
          </div> -->
          <div class="list-info">
            <div v-if="item.storeTypes && item.storeTypes.length > 0 && item.treeId === 1" class="list-info-item">
              {{ $t("类型") }}：
              <span v-if="item.storeTypes && item.storeTypes.length > 0">
                <span v-for="(type, typeIndex) in item.storeTypes" :key="typeIndex">
                  <span v-show="typeIndex > 0">·</span>
                  {{ $t(shopType[type]) }}
                </span>
              </span>
              <span v-else>-</span>
            </div>
            <!-- <span><span v-if="item.startBusinessTimeWeek === 0 && item.endBusinessTimeWeek === 0">{{ $t('周一') }}~{{ $t('周日') }}</span><span v-else>{{ $t(weeks[item.startBusinessTimeWeek]) }} ~ {{ $t(weeks[item.endBusinessTimeWeek ]) }}</span></span> -->
            <div v-if="
                item.startBusinessTimeWeek === 0 &&
                  item.endBusinessTimeWeek === 0
              ">
              {{ $t("营业时间") }}：<span><span v-if="
                    item.startBusinessTimeWeek === 0 &&
                      item.endBusinessTimeWeek === 0
                  ">{{ $t("周一") }}~{{ $t("周日") }}</span><span v-else>{{ $t(weeks[item.startBusinessTimeWeek]) }} ~
                  {{ $t(weeks[item.endBusinessTimeWeek]) }}</span></span>
              <!-- <span v-if="item.storeBusinessTimeDtoList && item.storeBusinessTimeDtoList.length > 0">
                <span v-for="(timeItem, timeIndex) in item.storeBusinessTimeDtoList" :key="timeItem.id" style="margin-right: 20px">
                  <span>
                    <span>{{ weeks[timeItem.weeks[0]] }}</span>
                    <span>~</span>
                    <span>{{ weeks[timeItem.weeks[timeItem.weeks.length - 1]] }}</span>
                  </span>
                  <span style="margin-right: 20px">
                    <span>{{ timeItem.startTime | timeFilter }}</span>
                    <span>-</span>
                    <span>{{ timeItem.endTime | timeFilter}}</span>
                  </span>
                  <el-divider v-if="timeIndex < item.storeBusinessTimeDtoList.length - 1"  direction="vertical"></el-divider>
                </span>
              </span>
              <span v-else>{{ $t('周一') }}~{{ $t('周日') }}</span> -->
            </div>
          </div>
          <div class="list-c2">
            <div>
              <div class="list-cost">
                <p v-if="item.breakfast > 0">
                  {{ $t("早餐") }}：<span class="cost-text">¥ {{ item.breakfast | moneyFilter
                    }}<span v-if="item.maxBreakfast">
                      - {{ item.maxBreakfast | moneyFilter }}</span></span>
                  <el-divider direction="vertical"></el-divider>
                </p>
                <p v-if="item.lunch > 0">
                  {{ $t("午餐") }}：<span class="cost-text">¥ {{ item.lunch | moneyFilter
                    }}<span v-if="item.maxLunch">
                      - {{ item.maxLunch | moneyFilter }}</span></span>
                  <el-divider direction="vertical"></el-divider>
                </p>
                <p v-if="item.dinner > 0">
                  {{ $t("晚餐") }}：<span class="cost-text">¥ {{ item.dinner | moneyFilter
                    }}<span v-if="item.maxDinner">
                      - {{ item.maxDinner | moneyFilter }}</span></span>
                </p>
              </div>
              <div class="list-detail">
                <p class="detail-content">
                  <span v-if="item.boxNumber" class="mr-20">{{ $t("包厢容纳人数") }}：{{ item.boxNumber }}</span>
                  <!-- <span v-else class="mr-20"></span> -->
                  <span v-if="item.seatsNumber">{{ $t("席位数") }}：{{ item.seatsNumber }}</span>
                  <!-- <span v-else></span> -->
                </p>
              </div>
            </div>
            <el-button class="cost-btn" size="medium" @click="detailHandle(item)">{{ $t("进入店铺") }}</el-button>
          </div>
          <div class="cost-discount">
            <div v-if="item.tripelletSaleRate" class="cost-title">
              Tripellet{{ $t("折扣率") }}：<span v-if="item.tripelletSaleRate" class="const-red-content">{{ item.tripelletSaleRate }}%</span><span v-else>-</span>
            </div>
            <div v-if="item.osakaSaleRate" class="cost-title">
              {{ $t("大阪乐游劵折扣率") }}：<span v-if="item.osakaSaleRate" class="const-red-content">{{ item.osakaSaleRate }}%</span><span v-else>-</span>
            </div>
            <!-- 折扣率 -->
            <!-- <div v-if="item.jrSaleRate" class="cost-title">
              JR ALLPASS{{ $t("折扣率") }}：<span v-if="item.jrSaleRate" class="const-red-content">{{ item.jrSaleRate }}%</span><span v-else>-</span>
            </div>
            <div v-if="item.tokyoSaleRate" class="cost-title">
              Tokyo Joyful{{ $t("折扣率") }}：<span v-if="item.tokyoSaleRate" class="const-red-content">{{ item.tokyoSaleRate }}%</span><span v-else>-</span>
            </div> -->
          </div>
          <div class="cost-discount">
            <div v-if="item.featureProvide" class="cost-title">
              {{ $t("特典提供") }}：<span>{{ item.featureProvide }}</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import { formatMoney } from '@/utils/money'
import { weeks, storeService, storeShopType } from '@/utils/public_data'

export default {
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
    timeFilter(val) {
      return val.substring(0, 5)
    },
  },
  data() {
    return {
      searchForm: {
        keywords: '',
        page: 1,
        pageSize: 10,
      },
      sizeAll: 0,
      dataList: [],
      service: storeService(),
      weeks: weeks(),
      imglist1:
        'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa0.att.hudong.com%2F30%2F29%2F01300000201438121627296084016.jpg&refer=http%3A%2F%2Fa0.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614823486&t=a246fc320a07d6426b9d99dac10e8553',
      imglist2:
        'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa0.att.hudong.com%2F30%2F29%2F01300000201438121627296084016.jpg&refer=http%3A%2F%2Fa0.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614823486&t=a246fc320a07d6426b9d99dac10e8553',
      shopLogo: require('@/assets/images/shop.png'),
      forward: require('@/assets/images/forward.png'),
      notImg: require('@/assets/images/not-image.png'),
      shopType: storeShopType(),
      thumbsSwiper: {},
      swiper: {},
      dataListLoading: false,
    }
  },
  methods: {
    initSwiper(index) {
      this.thumbsSwiper[index] = new Swiper(`.img-thumbs${index}`, {
        spaceBetween: 10,
        slidesPerView: 3,
        nextButton: `.swiper-button-next_${index}`,
        prevButton: `.swiper-button-prev_${index}`,
        loop: false,
        watchSlidesVisibility: true /*避免出现bug*/,
      })
      this.swiper[index] = new Swiper(`.img-top${index}`, {
        spaceBetween: 10,
        nextButton: `.swiper-button-next_${index}`,
        prevButton: `.swiper-button-prev_${index}`,
        speed: 1000,
        loop: false,
        controller: {
          control: this.thumbsSwiper[index],
        },
        thumbs: {
          swiper: this.thumbsSwiper[index],
        },
      })
    },
    init(list) {
      // this.getDataList()

      this.dataList = list
      if (this.dataList.length > 0) {
        this.dataList.map((item, index) => {
          let minPrice = item.breakfast
          let maxPrice = item.lunch
          if (minPrice == 0 || minPrice > item.dinner) {
            minPrice = item.dinner
          }
          if (minPrice == 0 || minPrice > item.lunch) {
            minPrice = item.lunch
          }
          if (maxPrice == 0 || maxPrice < item.dinner) {
            maxPrice = item.dinner
          }
          if (maxPrice == 0 || maxPrice < item.breakfast) {
            maxPrice = item.breakfast
          }
          this.$set(item, 'minPrice', minPrice)
          this.$set(item, 'maxPrice', maxPrice)
          if (item.storeImg) {
            this.$set(item, 'imgList', item.storeImg.split(','))
          }
          if (item.storeType) {
            this.$set(item, 'storeTypes', item.storeType.split(','))
          }
          if (item.imgList && item.imgList.length > 0) {
            this.$nextTick(() => {
              this.initSwiper(index)
            })
          }
        })
      }
    },
    // 进入店铺
    detailHandle(item) {
      this.$emit('saveForm')
      if (item.treeId === 1) {
        this.$router.push({ path: '/detail', query: { id: item.id } })
      } else {
        console.log(item.id)
        this.$router.push({
          path: '/departmentDetail',
          query: { id: item.id },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.list-top {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .list-top-title {
    height: 20px;
    // line-height: 20px;
    padding-left: 20px;
    border-left: 4px solid #e7b530;
    font-size: 24px;
    font-weight: 600;
  }
  .list-top-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .list-key-inp {
      margin-left: 20px;
      width: 200px;
    }
    .list-key-btn {
      margin-left: -2px;
      background: #e7b530;
      color: #000;
      font-weight: 600;
      zoom: -10;
    }
  }
}
.list-not {
  width: 100%;
  min-height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list {
  padding-top: 20px;
  border-top: 1px solid #dcdfe6;
  width: 100%;
  min-height: 260px;
  &:hover {
    transform: translateY(-1px);
    z-index: 20;
    background: rgba(240, 240, 240, 0.8);
  }
  .swiper-box {
    width: 180px;
    min-height: 250px;
    .list-left-image {
      width: 180px;
      height: 180px;
    }
    .list-image-thumbs {
      margin-top: 8px;
      width: 60px;
      height: 54px;
    }
  }
  .item-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .item-title-content {
      display: flex;
      align-items: center;
      .list-title-content {
        ::v-deep .el-link {
          font-size: 24px;
          font-weight: 400;
          color: #000;
          &:hover {
            color: #e7b530;
          }
        }
        margin-left: 10px;
      }
    }
    .shop-log {
      width: 28px;
    }
    .forward-logo {
      width: 28px;
    }
  }
  .list-rate {
    margin: 8px 0 10px;
    ::v-deep .el-rate__icon {
      font-size: 24px;
    }
    ::v-deep .el-rate__text {
      font-size: 18px;
    }
  }
  .list-service {
    margin-top: 12px;
    display: flex;
    .service-item {
      display: flex;
      .service-logo {
        width: 16px;
        margin-right: 10px;
      }
      .service-content {
        font-size: 12px;
      }
    }
  }
  .list-cost {
    margin-top: 20px;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    .cost-text {
      color: #f56c6c;
    }
  }
  .list-c2 {
    display: flex;
    justify-content: space-between;
    .cost-btn {
      background: #e7b530;
      color: #000;
      font-size: 14px;
      width: 120px;
      height: 40px;
    }
  }
  .cost-discount {
    margin: 16px 0;
    width: 80%;
    display: flex;
    justify-content: flex-start;
    .cost-title {
      margin-top: 0px;
      margin-right: 20px;
      font-size: 14px;
      .const-red-content {
        color: #f56c6c;
      }
    }
  }
  .list-info {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    .list-info-item {
      margin-right: 20px;
    }
  }
  .list-detail {
    margin-top: 12px;
    display: flex;
    .detail-content {
      // margin-top: 10px;
      // width: 700px;
      flex: 1;
      // height:20px;
      line-height: 20px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .detail-btn {
      width: 100px;
    }
  }
}
.list-page {
  text-align: center;
  .el-pagination {
    ::v-deep .btn-prev {
      background-color: #fff;
      border: 1px solid #dcdfe6;
    }
    ::v-deep .btn-next {
      background-color: #fff;
      border: 1px solid #dcdfe6;
    }
    ::v-deep .el-pager {
      .number {
        background-color: #fff;
        color: #909399;
        border: 1px solid #dcdfe6;
      }
      li:not(.disabled).active {
        background-color: #e7b530;
      }
    }
  }
}
.not-image {
  width: 180px;
  height: 180px;
  background: rgb(244, 244, 244);
}
.not-thumbs-box {
  margin-top: 8px;
  display: flex;
  .not-image2 {
    margin-right: 10px;
    background: rgb(244, 244, 244);
    width: 54px;
    height: 54px;
  }
}
.mr-20 {
  margin-right: 20px;
}
.top-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  background: url('../../assets/images/banner_hd.jpg');
  z-index: 1;
  .coverage {
    background: #fff;
    color: #4f2f24;
    padding: 30px;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
    .banner-title1 {
      font-size: 42px;
      font-weight: 600;
      padding: 60px 180px;
      border: 2px solid #4f2f24;
    }
    .banner-title1 span {
      font-size: 64px;
      font-weight: 800;
      margin-left: 16px;
    }
  }
}
</style>
