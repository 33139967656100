<template>
  <div v-loading="dataListLoading">
    <div
      v-for="(item, index) in dataList"
      :key="index"
      class="list"
      @click="handle(item)"
    >
      <div class="list-box">
        <div class="list-image-box">
          <el-image
            v-if="item.imgList && item.imgList.length > 0"
            :src="item.imgList[0]"
            fit="contain"
            lazy
            class="list-image"
          />
          <div v-else class="not-image"></div>
        </div>
        <div class="list-content">
          <div class="item-title">
            <div class="item-title-content">
              <p class="list-title-content">
                <el-link :underline="false" @click="handle(item)">{{
                  item.storeName
                }}</el-link>
              </p>
            </div>
          </div>
          <div class="list-rate">
            <!-- <el-rate v-model="item.storeScore" disabled :show-score="false" text-color="#ff9900"  score-template="{value}"> </el-rate> -->
          </div>
          <div v-if="item.storeTypes && item.storeTypes.length > 0 && item.treeId === 1" class="list-info-item">
            {{ $t("类型") }}：
            <span v-if="item.storeTypes && item.storeTypes.length > 0">
              <span
                v-for="(type, typeIndex) in item.storeTypes"
                :key="typeIndex"
              >
                <span v-show="typeIndex > 0">·</span>
                {{ $t(shopType[type]) }}
              </span>
            </span>
            <span v-else>-</span>
          </div>
          <div class="list-info-item">
            {{ $t("营业时间") }}：
            <span>
              <span
                v-if="
                  item.startBusinessTimeWeek === 0 &&
                    item.endBusinessTimeWeek === 0
                "
                >{{ $t("周一") }}~{{ $t("周日") }}</span
              >
              <span v-else
                >{{ $t(weeks[item.startBusinessTimeWeek]) }} ~
                {{ $t(weeks[item.endBusinessTimeWeek]) }}</span
              >
            </span>
          </div>
          <div v-if="item.featureProvide" class="list-info-item">
            <p>
              <span style="line-height: 14px"
                >{{ $t("特典提供") }}：{{ item.featureProvide }}</span
              >
            </p>
          </div>
          <div v-if="item.treeId === 1" class="list-info-item">
            <p>
              <span v-if="item.address" style="line-height: 14px"
                >{{ $t("地址") }}：{{ item.address }}</span
              >
              <span v-else>{{ $t("地址") }}：-</span>
            </p>
          </div>
          <div v-if="item.treeId === 2" class="list-info-item">
            <p>
              <span v-if="item.address" style="line-height: 14px"
                >{{ $t("地址") }}：{{ item.address }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="list-dis-box">
        <div class="cost-discount">
          <div class="cost-title">{{ $t('大阪乐游劵折扣率') }}：<span v-if="item.osakaSaleRate" class="const-red-content">{{ item.osakaSaleRate }}%</span><span v-else>-</span></div>
          <div class="cost-title">JR ALLPASS{{ $t('折扣率') }}：<span v-if="item.jrSaleRate" class="const-red-content">{{ item.jrSaleRate }}%</span><span v-else>-</span></div>
          <div class="cost-title">Tokyo Joyful{{ $t('折扣率') }}：<span v-if="item.tokyoSaleRate" class="const-red-content">{{ item.tokyoSaleRate }}%</span><span v-else>-</span></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { formatMoney } from "@/utils/money";
import { weeks, storeService, storeShopType } from "@/utils/public_data";
export default {
  filters: {
    moneyFilter(val) {
      return formatMoney(val);
    },
    timeFilter(val) {
      return val.substring(0, 5);
    }
  },
  data() {
    return {
      dataList: [],
      service: storeService(),
      weeks: weeks(),
      areaList: [],
      moneyList: {},
      imglist1:
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa0.att.hudong.com%2F30%2F29%2F01300000201438121627296084016.jpg&refer=http%3A%2F%2Fa0.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614823486&t=a246fc320a07d6426b9d99dac10e8553",
      imglist2:
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa0.att.hudong.com%2F30%2F29%2F01300000201438121627296084016.jpg&refer=http%3A%2F%2Fa0.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614823486&t=a246fc320a07d6426b9d99dac10e8553",
      shopLogo: require("@/assets/images/shop.png"),
      forward: require("@/assets/images/forward.png"),
      notImg: require("@/assets/images/not-image.png"),
      shopType: storeShopType(),
      dataListLoading: false
    };
  },
  methods: {
    init(list) {
      this.dataListLoading = true;
      this.dataList = [];
      this.dataList = list;
      if (this.dataList.length > 0) {
        this.dataList.map(item => {
          let minPrice = item.breakfast;
          let maxPrice = item.lunch;
          if (minPrice == 0 || minPrice > item.dinner) {
            minPrice = item.dinner;
          }
          if (minPrice == 0 || minPrice > item.lunch) {
            minPrice = item.lunch;
          }
          if (maxPrice == 0 || maxPrice < item.dinner) {
            maxPrice = item.dinner;
          }
          if (maxPrice == 0 || maxPrice < item.breakfast) {
            maxPrice = item.breakfast;
          }
          this.$set(item, "minPrice", minPrice);
          this.$set(item, "maxPrice", maxPrice);
          if (item.storeImg) {
            this.$set(item, "imgList", item.storeImg.split(","));
          }
          if (item.storeType) {
            this.$set(item, "storeTypes", item.storeType.split(","));
          }
          // if (item.imgList && item.imgList.length > 0) {
          //   this.$nextTick(() => {
          //     this.initSwiper(index)
          //   })
          // }
        });
      }
      this.dataListLoading = false;
    },
    handle(item) {
      this.$emit('saveForm')
      if (item.treeId === 1) {
        this.$router.push({ path: "/detail", query: { id: item.id } });
      } else {
        this.$router.push({
          path: "/departmentDetail",
          query: { id: item.id }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.not-image {
  width: 120px;
  width: 120px;
  background: rgb(244, 244, 244);
}
.detail-form {
  padding: 10px;
  box-sizing: border-box;
  ::v-deep .el-form-item {
    width: 100%;
    margin-bottom: 0px;
  }
  ::v-deep .el-form-item__label {
    margin-bottom: 0px;
    padding: 0;
  }
}
.test {
  width: 100%;
  min-height: 900px;
}
.top-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  background: url("../../assets/images/banner_hd.jpg") no-repeat;
  .banner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .banner-title {
      text-align: center;
      color: #fff;
      font-size: 30px;
    }
  }
}
.list-top {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .list-top-title {
    height: 20px;
    // line-height: 20px;
    padding-left: 20px;
    border-left: 4px solid #e7b530;
    font-size: 18px;
    font-weight: 600;
  }
  .list-top-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .list-key-inp {
      margin-left: 20px;
      width: 200px;
    }
    .list-key-btn {
      margin-left: -2px;
      background: #e7b530;
      color: #000;
      font-weight: 600;
      zoom: -10;
    }
  }
}
.special-box {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  height: 180px;
  background: url("../../assets/images/special.jpg") no-repeat center center;
  z-index: 1;
  border-radius: 3px;
  .content-box {
    padding: 10px;
    width: 80%;
    background: rgba(231, 181, 48, 0.7);
    border-radius: 2px;
    box-sizing: border-box;
    .content-inner-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border: 2px dashed #fff;
      border-radius: 6px;
      .special-title1 {
        margin-top: 10px;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
      .special-title2 {
        margin-top: 10px;
        font-size: 18px;
        color: #fff;
      }
      .enter-btn {
        padding: 7px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #333;
        &:hover {
          background: #fff;
          color: #e7b530;
          // border: 4px solid #E7B530;
        }
      }
    }
  }
}
.list {
  padding-top: 16px;
  border-top: 1px solid #dcdfe6;
  width: 100%;
  min-height: 140px;
  &:last-child {
    border-bottom: 1px solid #dcdfe6;
  }
  .list-dis-box {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #fdf6eb;
    .cost-discount {
      margin: 16px 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 12px;
      .cost-title {
        margin-top: 0px;
        font-size: 12px;
        .const-red-content {
          color: #f56c6c;
        }
      }
    }
  }
  .list-box {
    display: flex;
    flex-wrap: nowrap;
    .list-image-box {
      width: 120px;
      .list-image {
        width: 120px;
      }
    }
    .list-content {
      // padding: 0 6px;
      //
      flex: 1;
      // max-width: 240px;
      .list-cost {
        margin-top: 20px;
        display: flex;
        font-weight: 600;
        font-size: 12px;
        .cost-text {
          color: #f56c6c;
        }
      }
      .list-info-item {
        margin-top: 8px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 12px;
      }
      .list-rate {
        margin: 8px;
      }
      .item-title {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .item-title-content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          .list-title-content {
            width: 100%;
            margin-left: 0px;
            ::v-deep .el-link {
              display: block;
              width: 240px;
              font-size: 16px;
              font-weight: 600;
              color: #000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:hover {
                color: #e7b530;
              }
            }
            ::v-deep .el-link--inner {
              display: block;
              width: 100%;
              font-size: 16px;
              font-weight: 600;
              color: #000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:hover {
                color: #e7b530;
              }
            }
            margin-left: 10px;
          }
        }
        .shop-log {
          width: 28px;
        }
        .forward-logo {
          width: 28px;
        }
      }
    }
  }
}
.list-page {
  margin-top: 20px;
  text-align: center;
  .el-pagination {
    ::v-deep .btn-prev {
      background-color: #fff;
      border: 1px solid #dcdfe6;
    }
    ::v-deep .btn-next {
      background-color: #fff;
      border: 1px solid #dcdfe6;
    }
    ::v-deep .el-pager {
      .number {
        background-color: #fff;
        color: #909399;
        border: 1px solid #dcdfe6;
      }
      li:not(.disabled).active {
        background-color: #e7b530;
      }
    }
  }
}
.list-not {
  width: 100%;
  min-height: 160px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-box {
  width: 100%;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  .search-warpper {
    display: flex;
    .search-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      .btn-icon {
        font-size: 20px;
      }
    }
  }
}
.search-box2 {
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  height: 30px;
  background: #fff;
  padding: 10px 0;
  z-index: 1000;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  .search-warpper {
    display: flex;
  }
}
.cost-btn {
  background: #e7b530;
  color: #fff;
}
</style>
